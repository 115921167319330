import React, { useContext, useEffect, useState } from 'react';
import { Section } from '../components/Core';
import { Col, Container, Row } from 'react-bootstrap';
import GlobalContext from '../context/GlobalContext';
import Seo from '../components/Seo/Seo';
import OrderJourneySteps from '../sections/OrderJourneySteps';
import { CheckoutStepOne } from '../sections/CheckoutSteps/StepOne';
import { CheckoutStepTwo } from '../sections/CheckoutSteps/StepTwo';
import { CheckoutStepThree } from '../sections/CheckoutSteps/StepThree';
import { CheckoutStepFour } from '../sections/CheckoutSteps/StepFour';
import { CheckoutStepFive } from '../sections/CheckoutSteps/StepFive';
import RecontractOrderJourneySteps from '../sections/RecontractOrderJourneySteps';
import { navigate } from 'gatsby';

const CheckoutPage = (props: any) => {
  const gContext: any = useContext(GlobalContext);
  const checkoutStep = gContext?.goGetRegistrationData()?.checkoutStep || 1;
  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const step = params.get('step');
    gContext.goSetRegistrationData({
      ...gContext.goGetRegistrationData(),
      checkoutStep: step ? parseInt(step) : 1,
    });
  }, [props.location.search]);

  useEffect(() => {
    if (
      gContext.goGetRegistrationData()?.searchPremiseResults?.properties?.SalesStatus !== 'ORDER' &&
      gContext.goGetRegistrationData()?.searchPremiseResults?.properties?.IsBDUK !== 'true'
    ) {
      navigate('/')
    }
  })

  const stepRouting = () => {
    if (gContext.goGetRegistrationData()) {
      switch (checkoutStep) {
        case 1:
          return <CheckoutStepOne location={props?.location?.state?.location}/>;
        case 2:
          return gContext.goGetRegistrationData().skipBooking ? <CheckoutStepThree /> : <CheckoutStepTwo />;
        case 3:
          return gContext.goGetRegistrationData().skipBooking ? <CheckoutStepFour /> : <CheckoutStepThree />;
        case 4:
          return <CheckoutStepFour />;
        case 5:
          return <CheckoutStepFive />;
        default:
          <span>Something went wrong, no checkout step specified.</span>;
      }
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Seo page="checkout" featuredImageUrl={undefined} />
      <Section>
        <Container className="checkoutOrder">
          {/* Order Journey Step Indicator */}
          <Row className="justify-content-center">
            <Col sm={12} md={8} className="text-center mb-1 mb-md-3 mt-1">
              {
                gContext.goGetRegistrationData() &&
                (
                  gContext.goGetRegistrationData().skipBooking ?
                    <RecontractOrderJourneySteps currentStep={checkoutStep} /> :
                    <OrderJourneySteps currentStep={checkoutStep} />
                )
              }
            </Col>
          </Row>

          {/* Step Router */}
          {stepRouting()}
        </Container>
      </Section>
    </>
  );
};

export default CheckoutPage;
